.list {
    padding: 0;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1vw 2vw;
    width: calc(100% - 21.88vw - 12.5vw);
    margin-left: 21.88vw;
    margin-right: 12.5vw;
    font-family: "Montserrat";
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 10px 20px;

    .item {
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #0466ad;
        cursor: pointer;
        box-sizing: border-box;
        white-space: nowrap;
        text-align: center;

        &::after {
            content: "";
            position: absolute;
            left: -4px;
            top: 20px;
            width: 105%;
            height: 2px;
            background-color: transparent;
        }

        &:hover {
            &::after {
                background-color: #0466ad;
            }
        }

        &-active {
            position: relative;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #6e6e6e;
            cursor: pointer;
            box-sizing: border-box;
            white-space: nowrap;
            text-align: center;

            &::after {
                content: "";
                position: absolute;
                left: -4px;
                top: 20px;
                width: 105%;
                height: 2px;
                background-color: #6e6e6e;
            }
        }
    }
}
