@font-face {
  font-family: Montserrat;
  src: url(/static/fonts/Montserrat-Regular.woff2) format("woff2"), url(/static/fonts/Montserrat-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Montserrat;
  src: url(/static/fonts/Montserrat-Medium.woff2) format("woff2"), url(/static/fonts/Montserrat-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Montserrat;
  src: url(/static/fonts/Montserrat-MediumItalic.woff2) format("woff2"), url(/static/fonts/Montserrat-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: Montserrat;
  src: url(/static/fonts/Montserrat-SemiBold.woff2) format("woff2"), url(/static/fonts/Montserrat-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Montserrat;
  src: url(/static/fonts/Montserrat-Bold.woff2) format("woff2"), url(/static/fonts/Montserrat-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

:root {
  --color-primary: #0056a4;
  --color-primary-2: #04bbd2;
  --color-primary-light-1: #e3f2ff;
  --color-primary-dark-1: #063c6c;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-grey-light-1: #e6ebf1;
  --color-grey-1: #717585;
  --color-orange-1: #fa755a;
  --color-red-1: #c12d24;
  --color-green-1: #4bcf3b;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: var(--color-white);
  overflow-x: hidden;
}

body>svg {
  display: none;
}

main {
  min-height: calc(100vh - 74px - 40px);
}

::selection {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(to right, var(--color-primary), var(--color-primary-dark-1));
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s;
}

.heading-secondary:hover {
  transform: scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.4);
}

.heading-tertiary {
  -webkit-background-clip: text;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  margin-bottom: 1rem;
}

.paragraph {
  margin-top: 1rem;
  font-size: 1.6rem;
  text-align: justify;
}

.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.paragraph--center {
  text-align: center;
}

.paragraph--smallmargin:not(:last-child) {
  margin-bottom: 0;
  margin-top: .5rem;
}

.link {
  transition: all .2s;
}

.link *,
.link:link,
.link:visited {
  text-decoration: underline;
  color: var(--color-white);
}

.link:hover {
  text-shadow: 0 0 1rem rgba(255, 255, 255, 0.7);
}

sup {
  font-size: 1.6rem;
  color: var(--color-red-1);
}

.label {
  font-size: 2rem;
  color: var(--color-white);
  width: 100%;
  margin-bottom: 1rem;
  display: inline-block;
  text-align: left;
  transition: all .2s;
  margin-top: 2rem;
}

.label:first-child {
  margin-top: 0;
}

.label--center {
  text-align: center;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 2rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.ubercontainer {
  font-family: Montserrat;
  font-style: normal;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.section-notifications {
  color: var(--color-primary-dark-1);
  font-size: 300%;
}

/*For all of the cards*/
.Stripe .paragraph {
  font-size: 14px;
}

#status {
  width: 32px;
  height: 32px;
}

.section-payment__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.StripeElement {
  color: var(--color-primary-dark-1);
  font-family: "Helvetica";
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0 1px 3px 0 var(--color-grey-1);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  text-align: center;
}

.StripeElement::-webkit-placeholder {
  color: #3f95e4;
  font-family: "Helvetica";
  font-size: 1.6rem;
}

.StripeElement::placeholder {
  color: #3f95e4;
  font-family: "Helvetica";
  font-size: 1.6rem;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 var(--color-primary-dark-1);
}

.StripeElement--invalid {
  border-color: var(--color-orange-1);
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.agree {
  margin-right: 10px;
}

/*Ovsov*/
@media only screen and (max-width: 750px) {

  .StripeElement,
  .Name {
    position: relative;
    min-width: 120px;
  }

  .form-row,
  .col,
  .Stripe,
  .payment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;
  }
}

/*Thank you page start*/
.section-thank-you {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  min-height: calc(100vh - 74px - 40px);
}

.section-thank-you__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.section-thank-you__content--text {
  width: 43rem;
  color: var(--color-primary-dark-1);
  text-align: center;
  font-size: 1.6rem;
}

.section-thank-you__content--image img {
  height: 300px;
  min-height: 100px;
  width: 300px;
}

/*Thank you page end*/
.section-notifications span {
  padding: 0.5rem;
  border: solid 1px var(--color-black);
  border-radius: 1rem;
}

a,
a:visited {
  text-decoration: none;
}

.private-pages__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5rem 0 3rem;
  width: 100%;
}

.private-pages__wrapper--center {
  justify-content: center;
}

.private-pages__navigation {
  flex-grow: 1;
  padding: 1rem 2rem;
  border-bottom: 3px solid var(--color-primary-dark-1);
}

.private-pages__navigation--list {
  list-style: none;
}

.private-pages__navigation--link {
  color: var(--color-black);
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: 600;
}

.section-licenses {
  flex-grow: 3;
  margin: 0 10rem;
}

.licences-card-description-common {
  max-width: 60rem;
}

.section-licenses-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  border: 5px solid var(--color-primary-light-1);
  width: 100%;
  color: var(--color-black);
  background-color: var(--color-primary-light-1);
}

.section-licenses-card:not(:last-child) {
  margin-bottom: 3rem;
}

.section-licenses-card__main-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  background-color: var(--color-white);
  padding: 2rem;
}

.section-licenses-card__odd-information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
}

.section-licenses-card__odd-information div {
  font-size: 1.6rem;
}

.section-licenses-card__odd-information div span {
  font-weight: 900;
}

.section-licenses-card__odd-information--column {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.section-licenses-card__main-information--name,
.section-licenses-card__main-information--key {
  font-size: 2rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section-licenses-card__main-information--key span:first-child {
  font-size: 1.6rem;
  font-weight: 400;
  margin-right: 1rem;
}

.section-licenses-card__log-information {
  padding: 0 0 20px;
  width: 100%;
}

.section-licenses-card__log-information .code_wrapper {
  width: 100%;
  background-color: #303030;
  color: #ffffff;
  border-radius: 4px;
  padding: 10px;
  max-height: 400px;
  overflow: auto;
  font-size: 12px;
  line-height: 150%;
  font-weight: 600;
}

.section-licenses-card__log-information .code_wrapper {
  scrollbar-width: thin;
  scrollbar-color: #a8afb5 #303030;
}

.section-licenses-card__log-information .code_wrapper::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 20px
}

.section-licenses-card__log-information .code_wrapper::-webkit-scrollbar-track {
  background: #303030;
  border-radius: 20px
}

.section-licenses-card__log-information .code_wrapper::-webkit-scrollbar-thumb {
  background-color: #a8afb5;
  border-radius: 20px;
  border: 3px solid #303030
}

.section-licenses-card__log-information .code_wrapper code {
  white-space: normal;
}

.section-licenses__heading {
  font-size: 3rem;
  color: var(--color-black);
  text-align: left;
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

.section-licenses__button {
  /* padding: 0.75rem 0.5rem; */
  padding: 1rem 2rem;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: 14px;
  margin-bottom: 1rem;
  border-radius: 1rem;
  transition: all .2s;
  /* width: 20rem; */
  box-sizing: border-box;
  text-align: center;
}

.section-licenses__button:hover {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

.section-licenses__button:active {
  transform: translateY(1px);
  box-shadow: none;
}

.section-licenses__button,
.section-licenses__button:active,
.section-licenses__button:focus {
  outline: none;
}

.section-account-details {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.section-account-details__heading {
  font-size: 3rem;
  font-weight: 900;
  text-transform: capitalize;
  margin-bottom: 3rem;
}

.section-account-details__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 600;
  border: 5px solid var(--color-primary-light-1);
  background-color: var(--color-primary-light-1);
  border-radius: 5px;
  margin-bottom: 3rem;
}

.section-account-details__content-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  width: 100%;
  padding: 2rem;
}

.section-account-details__content-column--1 {
  color: #333;
  padding-right: 0;
}

.section-account-details__content-column--2 {
  color: #333;
  padding-left: 0;
}

.section-account-details__content-column--2 span:first-child {
  font-size: 1.28rem;
  padding-top: 1.5rem;
}

.section-account-details__content-column span {
  background-color: var(--color-white);
  width: 100%;
  padding: 1rem 2rem;
  height: 5rem;
}

.section-account-details__content-column span:not(:last-child) {
  margin-bottom: 1.5rem;
}

.section-account-details__content-link {
  padding: 1.75rem 5.5rem;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--color-primary-dark-1);
  color: var(--color-white);
  font-size: 1.6rem;
  margin-bottom: 1rem;
  border-radius: 4rem;
  transition: all .2s;
  margin-top: 3rem;
}

.section-account-details__content-link:hover {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
}

.section-account-details__content-link:active {
  transform: translateY(1px);
  box-shadow: none;
}

.section-account-details__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  width: 100%;
  padding: 2rem;
  background-color: var(--color-primary-light-1);
}

.section-account-details__column--top-border-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.section-account-details__column--bottom-border-radius {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.section-account-details__column--alert {
  margin-bottom: 2rem;
  text-align: center;
  color: var(--color-red-1);
  animation-name: shakeEffect;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes shakeEffect {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.section-account-details__column--info {
  color: var(--color-red-1);
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.section-account-details__column--el:not(:last-child) {
  margin-bottom: 1.5rem;
}

.section-account-details__column--heading {
  font-size: 2rem;
  font-weight: 600;
}

.section-account-details__column--input {
  border: none;
  width: 28rem;
  height: 3.5rem;
  padding: 0 1rem;
}

.section-account-details__column--button {
  padding: 0.75rem 2.5rem;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--color-primary-dark-1);
  color: var(--color-white);
  font-size: 1.6rem;
  margin-bottom: 1rem;
  border-radius: 4rem;
  transition: all .2s;
  outline: none;
}

.section-account-details__column--button--red {
  background-color: var(--color-red-1);
}

.section-account-details__column--button:disabled {
  background-color: var(--color-grey-1);
}

.section-account-details__column--button:disabled:hover {
  transform: none;
  box-shadow: none;
  cursor: auto;
}

.section-account-details__column--button:hover {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

.section-account-details__column--button:active {
  transform: translateY(1px);
  box-shadow: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Cancel page start*/
.section-cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  margin-bottom: 7rem;
  color: var(--color-white);
  background-color: var(--color-primary-2);
  padding: 4rem 7rem;
  border-radius: 10px;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
}

.section-cancel__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* width: 60rem; */
}

.section-cancel__form--content {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.section-cancel__form--reason {
  display: flex;
  flex-direction: column;
}

.section-cancel__form--label {
  font-size: 1.6rem;
  cursor: pointer;
}

.section-cancel__form--input {
  margin-right: 1rem;
}

.section-cancel__form--input-label {
  cursor: pointer;
  display: block;
}

.section-cancel__form--input-label:last-child {
  margin-bottom: 1.5rem;
}

.section-cancel__form--button {
  border: none;
  margin-top: 1rem;
  background-color: var(--color-primary);
  padding: 1.5rem 7rem;
  border-radius: 5rem;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all .2s;
  color: var(--color-white);
}

.section-cancel__form--key {
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
}

.section-cancel__form--key-input {
  width: 26rem;
}

.section-cancel__form--button:hover {
  transform: translateY(-3px);
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.3);
}

.section-contact-us {
  padding: 2rem 5rem;
  margin-bottom: 4rem;
  background-color: var(--color-primary-dark-1);
  margin-top: 2.5rem;
}

.section-contact-us p {
  text-align: center;
}

.section-login {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-login__heading {
  font-size: 3rem;
  font-weight: 900;
  text-transform: capitalize;
  margin-bottom: 3rem;
}

.section-login__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65rem;
  font-weight: 600;
  border-radius: 5px;
  /* margin-bottom: 3rem; */
}

.section-login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 5rem;
}

.section-login__form--label {
  font-size: 2rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.section-login__form--input {
  margin-bottom: 3.5rem;
  border: none;
  width: 28rem;
  height: 3.5rem;
  padding: 0 1rem;
  background-color: var(--color-white);
  text-align: center;
}

.section-login__form--button {
  padding: 8px 12px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #0466ad;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition: 300ms;
  width: 120px;
  margin: 20px 1rem 1rem;
}

.section-login__form--button:disabled {
  background-color: #888288;
}

.section-login__form--button:enabled:hover {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

.section-login__form--button:active {
  transform: translateY(1px);
  box-shadow: none;
}

.section-login__forgot-password {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #2689e0;
  transition: all .5s;
}

.section-login__forgot-password:hover {
  transform: scale(1.1);
}

.section-login__have {
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 5rem;

}

.section-reset-password {
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-reset-password__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65rem;
  font-weight: 600;
  border-radius: 5px;
  margin-bottom: 3rem;
}

.section-reset-password__content--text {
  margin-top: 2rem;
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.section-reset-password__content--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.section-reset-password__content--label {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.color-red {
  color: var(--color-red-1);
}

.color-green {
  color: var(--color-green-1);
}

.section-failed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 235em) {

  .btn,
  .btn:link,
  .btn:visited {
    font-size: 4rem;
    padding: 6rem 18rem;
  }

  .heading-secondary {
    font-size: 9rem;
  }

  .paragraph {
    font-size: 3.5rem;
  }

  body {
    padding: 9rem;
  }
}

@media (min-width: 160em) {
  .heading-tertiary {
    font-size: 5rem;
  }

  .heading-secondary {
    font-size: 6rem;
  }

  .paragraph {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 93.75em) {
  .section-payment {
    padding: 3rem 10rem;
  }
}

@media only screen and (max-width: 81.25em) {
  .section-payment {
    padding: 3rem 3rem;
  }
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 1000px) {
  body {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }

  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }

  body {
    padding: 0;
    overflow-x: hidden;
  }

  .paragraph {
    font-size: 2.5rem;
    text-align: center;
  }

  .heading-secondary {
    margin-top: 2rem;
    font-size: 4.5rem;
  }

  .heading-secondary--small {
    font-size: 3.5rem;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 1.5rem 4rem;
    font-size: 2rem;
  }

  .heading-tertiary {
    text-align: center;
    font-size: 3.5rem;
  }

  html {
    font-size: 50%;
  }

  .StripeElement::placeholder {
    font-size: 2rem;
  }

  .StripeElement {
    font-size: 2.5rem;
    height: 6rem;
  }

  .section-licenses {
    flex-grow: 0;
    width: 90%;
    margin: 0 auto;
  }

  .nav-container {
    min-height: 7.5rem;
  }

  .navigation__content {
    width: 100%;
    justify-content: flex-start;
    margin-left: 4rem;
  }

  .footer {
    flex-direction: column;
    font-size: 2.5rem;
    height: 12rem;
  }

  .section-cancel {
    padding: 0;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .section-cancel__form--label {
    font-size: 3rem;
  }

  .section-cancel__form--button {
    padding: 1.75rem 6rem;
    font-size: 2.5rem;
  }

  .section-contact-us {
    margin-top: 3rem;
  }

  .coupon-box__content .StripeElement {
    height: 5rem;
  }

  .coupon-box__content--input {
    margin-right: -11rem;
    margin-left: -4rem;
  }

  .label {
    margin-top: 4rem;
  }

  .section-products__item:not(:first-child) {
    margin-top: 4rem;
  }

  .section-products__item {
    width: 39rem;
    height: 43.5rem;
    margin-top: 4rem;
  }

  .section-products__heading #text {
    font-size: 3rem;
    margin-right: 2rem;
  }

  .section-products__content div {
    font-size: 2.25rem;
  }

  .section-products__item button {
    font-size: 2.25rem;
  }

  .section-change-payment-method-for-one-product__new--content-label {
    /* font-size: 1.8rem; */
  }

  .section-change-payment-method-for-one-product__new--content-alert {
    font-size: 1.6rem;
  }

  .section-download__content-el {
    font-size: 2rem;
  }

  .section-reset-password__content {
    width: 75rem;
  }

  .section-cancelation__heading {
    font-size: 3.5rem;
  }

  .nav-container {
    justify-content: center;
  }

  .nav-logo {
    margin-left: 0;
    margin-right: 0;
  }

  .navigation__link {
    margin-top: 0;
    font-size: 3rem;
    margin-left: 3rem;
  }

  .section-account-details__heading {
    font-size: 3.25rem;
  }

  .section-account-details__column {
    font-size: 1.8rem;
  }

  .section-account-details__column--heading {
    font-size: 2.5rem;
  }

  .section-account-details__content-column {
    font-size: 1.8rem;
  }

  .section-account-details__column--input {
    width: 47rem;
    height: 4rem;
  }

  .section-account-details__column--el:not(:last-child) {
    margin-bottom: 2rem;
  }

  .section-account-details__content-column span {
    font-size: 2rem;
  }

  .section-account-details__content-column--2 span:first-child {
    font-size: 2rem;
    padding-top: 1rem;
  }

  .section-account-details__column--button {
    font-size: 1.8rem;
    padding: 2.25rem 5.5rem;
  }

  .section-login__content {
    width: 75rem;
  }

  .section-login__form--button {
    font-size: 1.6rem;
    padding-top: 1.25rem;
  }

  .section-login__heading {
    font-size: 3.5rem;
  }

  .section-login__form--label {
    font-size: 2.5rem;
  }

  .section-login__form--input {
    width: 47rem;
    height: 4rem;
  }

  .section-login__form--button {
    font-size: 1.8rem;
    padding: 2.25rem 5.5rem;
  }

  .section-licenses__button {
    /* font-size: 1.8rem; */
    /* padding: 1rem 2rem; */
  }

  .section-login__forgot-password {
    font-size: 1.6rem;
  }

  .section-licenses-card__odd-information div {
    font-size: 2rem;
  }

  .section-licenses-card__main-information--name,
  .section-licenses-card__main-information--key {
    font-size: 2.25rem;
  }

  .section-licenses__heading {
    font-size: 3.5rem;
  }

  .section-licenses-card__main-information--key span:first-child {
    font-size: 1.8rem;
  }

  .section-change-payment-method-for-one-product__content {
    width: 75rem;
  }

  .section-change-payment-method-for-one-product__column span {
    height: 9rem;
  }

  .section-payment__calculating-price {
    margin-bottom: 3rem;
    font-size: 2.5rem;
    width: 100%;
  }

  .section-payment__btn {
    font-size: 2.5rem;
  }

  .section-payment__payment-form {
    padding: 4rem 3.5rem;
    margin-bottom: 3rem;
  }

  .section-payment__secure-images--img-2 {
    height: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  body {
    padding: 0;
  }

  main {
    min-height: calc(100vh - 74px - 120px);
  }

  .u-margin-bottom-big {
    margin-bottom: 1rem !important;
  }

  .paragraph {
    text-align: center;
    font-size: 1.8rem;
  }

  .heading-secondary {
    font-size: 3rem;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 1.5rem 5rem;
    font-size: 2rem;
  }

  .heading-tertiary {
    text-align: center;
    font-size: 2.25rem;
  }

  .label {
    font-size: 2.6rem;
  }

  .section-products__item {
    height: 38.5rem;
    width: 35rem;
  }

  .section-products__heading #text {
    font-size: 2.6rem;
  }

  .section-products__content div {
    font-size: 1.8rem;
  }

  .section-products__item button {
    font-size: 1.8rem;
  }

  .coupon-box__content .StripeElement {
    width: 30rem;
    margin-left: 1rem;
  }

  .coupon-box__content--input {
    margin-right: 5rem;
    margin-left: 0;
  }

  .StripeElement::placeholder {
    font-size: 1.8rem;
  }

  .StripeElement {
    font-size: 2rem;
    height: 4rem;
    width: 32rem;
  }

  .section-thank-you__content--text {
    margin-bottom: 3rem;
    margin-right: 0;
    width: 100%;
  }

  .section-cancel {
    padding: 0 3rem;
    margin-bottom: 0;
  }

  .footer {
    font-size: 2rem;
    height: 14rem;
    justify-content: center;
  }

  .footer-navigation__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-cancel__form--content {
    flex-direction: column;
  }

  .section-cancel__form--reason {
    margin-bottom: 3rem;
  }

  .section-cancel__form--button {
    padding: 2rem 4rem;
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .section-download__content-el {
    font-size: 1.8rem;
  }

  .section-reset-password__content {
    width: 40rem;
  }

  .section-cancelation__heading {
    font-size: 2.25rem;
  }

  .nav-container {
    min-height: 5.5rem;
  }

  .navigation__content {
    margin-left: 2rem;
  }

  .nav-logo {
    max-width: 4.75rem;
  }

  .navigation__link {
    font-size: 2rem;
    margin-left: 2rem;
  }

  .section-account-details__content-column {
    font-size: 1.8rem;
    padding: 1rem;
  }

  .section-account-details__column {
    font-size: 1.6rem;
  }

  .section-account-details__content-column--1 {
    padding-right: 0;
  }

  .section-account-details__content-column--2 {
    padding-left: 0;
    padding-right: 0;
  }

  .section-account-details__content-column--2 span:first-child {
    font-size: 1.6rem;
    padding-top: 1.25rem;
  }

  .section-account-details__column--button {
    padding: 1.25rem 3.5rem;
  }

  .section-licenses__button {
    /* padding: 1.25rem 3.5rem; */
  }

  .section-account-details__column--input {
    width: 36rem;
  }

  .section-login__content {
    width: 40rem;
  }

  .section-login__form--button {
    font-size: 1.6rem;
    padding-top: 1.25rem;
  }

  .section-login__form--input::placeholder {
    font-size: 1.8rem;
  }

  .section-login__forgot-password {
    font-size: 1.4rem;
  }

  .section-login__form--label {
    font-size: 2rem;
  }

  .section-login__heading {
    font-size: 3rem;
  }

  .section-login__form--input {
    width: 36rem;
  }

  .section-licenses-card__odd-information div {
    font-size: 1.8rem;
  }

  .section-licenses-card__main-information--name,
  .section-licenses-card__main-information--key {
    font-size: 2rem;
  }

  .section-licenses__heading {
    font-size: 3rem;
  }

  .section-licenses-card__main-information--key span:first-child {
    font-size: 1.8rem;
  }

  .section-licenses-card__odd-information {
    flex-direction: column;
  }

  .section-licenses-card__odd-information--column:first-child {
    margin-bottom: 1.5rem;
  }

  .section-licenses-card__main-information {
    flex-direction: column;
  }

  .section-licenses-card__main-information--name {
    margin-bottom: 1rem;
  }

  .section-licenses-card__main-information--key {
    flex-direction: column;
    align-items: flex-start;
  }

  .StripeElement {
    padding: 6px 12px;
  }

  .section-change-payment-method-for-one-product__column span {
    height: 13rem;
  }

  .section-change-payment-method-for-one-product__content {
    width: 36rem;
  }

  .coupon-box__content {
    flex-direction: column;
  }

  .coupon-box__content--input {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 18.75em) {
  .section-products__item {
    min-width: 23rem;
  }

  .section-products__heading #text {
    font-size: 1.75rem;
  }

  .StripeElement,
  .Name {
    min-width: 97px;
  }
}

.link-button {
  padding: 1.2rem 2.2rem;
  background-color: var(--color-primary-dark-1);
  color: var(--color-white);
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 3rem;
  transition: all 0.2s;
  cursor: pointer;
  margin-bottom: 1rem;
}

.link-button:hover {
  background-color: var(--color-white);
  color: var(--color-primary-dark-1);
}

.form__button {
  font-size: 1.8rem;
  padding: 2.25rem 5.5rem;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--color-primary-dark-1);
  color: var(--color-white);
  margin-bottom: 1rem;
  border-radius: 4rem;
  transition: all .2s;
  outline: none;
}

.form__button:disabled {
  background-color: #888288;
}

.form__wrap {
  margin-bottom: 3.5rem;
}

.form__input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.form__label {
  display: block;
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  text-align: center;
}

.form__input {
  border: none;
  width: 47rem;
  height: 4rem;
  padding: 0 1rem;
  background-color: var(--color-white);
  text-align: center;
}

.Toastify {
  font-size: 16px;
}

.Toastify__toast {
  padding: 20px;
}

.loader-wrap {
  text-align: center;
  padding: 20px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.ReactModal__Content {
  background: #ffffff !important;
  border-radius: 10px !important;
}

.ReactModalPortal .signin-form {
  margin: 0;
}

.ReactModalPortal .section-login__content {
  border: none;
  background: none;
  margin-bottom: 1rem;
}

.ReactModalPortal .signin-form {
  border: none;
  background: none;
}

.ReactModalPortal .section-login__forgot-password {
  margin: 0;
}

.ReactModalPortal .form__button {
  padding: 1.5rem 3rem;
}

.ReactModalPortal h1 {
  margin-bottom: 2rem;
}

.license-status {
  text-transform: capitalize;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.form-cpm {
  font-family: "Montserrat";
}

.form-cpm .form-row + .form-row {
  margin-top: 20px;
}

.form-cpm .form-row .label {
  max-width: 295px;
  font-weight: 700;
  font-size: 18px;
  line-height: 133%;
  color: #0466ad;
  text-align: left;
}

.app-version {
  color: black;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.link-button-hb {
  border: 1px solid #fff;
  margin: 0;
  height: 30px;
  padding: 0 1.2rem;
  font-weight: normal;
}

.link-button-hb:hover {
  border: 1px solid var(--color-primary-dark-1);
}

.link-button-hb:focus {
  outline: none;
}


* {
  scrollbar-width: thin;
  scrollbar-color: #a8afb5 #ececec
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 20px
}

::-webkit-scrollbar-track {
  background: #ececec;
  border-radius: 20px
}

::-webkit-scrollbar-thumb {
  background-color: #a8afb5;
  border-radius: 20px;
  border: 3px solid #ececec
}