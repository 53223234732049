.menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: calc(80px + 5.21vw);
    box-sizing: border-box;
    padding: 24px clamp(8px, 1.04vw, 20px);
    z-index: 1;
    background: linear-gradient(90deg, #0f7cc1 0.64%, #19a4df 49.8%, #1db7eb 100%);

    display: flex;
    flex-direction: column;
    gap: 36px;
}

.link {
    width: 100%;
    height: auto;
    padding: clamp(7px, 0.63vw, 12px);
    border: 2px solid transparent;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: clamp(14px, 0.94vw, 18px);
    line-height: 120%;
    color: #ffffff;
    text-align: center;
    transition: background 0.2s ease-in-out;
    border-radius: 16px;
    gap: clamp(8px, 0.7vw, 13.4px);

    * {
        pointer-events: none;
    }

    &:hover,
    &-active {
        background: rgba(255, 255, 255, 0.15);
        border: 2px solid #ffffff;
        color: #ffffff;
    }

    svg {
        width: clamp(25px, 2.13vw, 40.86px);
    }
}

@media screen and (max-width: 768px) {
    .menu {
        flex-direction: column;
        gap: 10px;
    }
}
