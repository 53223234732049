.overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.15);
    width: 100vw;
    height: 100vh;
    z-index: 999;
    padding: 20vh 2vw;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
}

.modal {
    position: relative;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    width: fit-content;
    max-width: 350px;
    height: max-content;
    background: #ffffff;
    font-family: "Montserrat";
    text-align: center;
    z-index: 1000;
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    span {
        font-weight: 700;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-title {
        margin: 0 auto;
        max-width: 295px;
        font-weight: 700;
        font-size: 18px;
        line-height: 133%;
        text-align: center;
        color: #0466ad;
    }

    &-text {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #333333;
    }
}

.options {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-accept {
        padding: 16px 25px;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 20px;
        background-color: #0466ad;
        color: #fff;
        border: 1px solid transparent;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
        transition: 300ms;

        &:hover {
            border: 1px solid #6e6e6e;
            background-color: #fff;
            color: #6e6e6e;
        }
    }

    &-close {
        padding: 16px 20px;
        display: flex;
        align-items: center;
        height: 20px;
        background-color: #fff;
        border: 1px solid #0466ad;
        border-radius: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
        color: #0466ad;
        cursor: pointer;
        transition: 300ms;

        &:hover {
            opacity: 0.5;
        }
    }
}

.form {
    padding: 0 0 0 15px;

    .fieldset {
        padding: 0 0 15px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        border: none;

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    .nested-fieldset {
        padding: 0;
        // padding-left: 5px;
        display: flex;
        gap: 5px;
        flex-direction: column;
        align-items: flex-start;
        border: none;

        .label {
            margin-left: 15px;
        }

        .legend {
            .label {
                margin-left: 0;
            }

            .input {
                margin: 0;
            }
        }
    }

    .legend {
        padding-bottom: 8px;
        width: 100%;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
    }

    .label {
        position: relative;
        display: flex;
        gap: 15px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #6e6e6e;
        cursor: pointer;
        width: 100%;

        &.checkbox_label {
            padding-left: 25px;
            &::before {
                content: "";
                width: 13px;
                height: 13px;
                border: 1px solid #dadee8;
                position: absolute;
                left: 0;
                top: 2px;
                transition: 0.25s ease;
                cursor: pointer;
            }
        }
    }

    .input[type="checkbox"] {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 1001;
        width: 200px;
        opacity: 0;
        cursor: pointer;
    }

    .input[type="text"] {
        width: 85%;
        border: 1px solid #6e6e6e;
        outline: none;
        margin: 5px auto;
        padding: 3px;
    }

    .input:checked + .icon {
        display: initial;
    }

    // Firefox not support
    .label:has(.input:checked) {
        &::before {
            border: 1px solid #0466ad;
        }
    }

    .icon {
        position: absolute;
        display: none;
        left: 2px;
        top: 4px;
    }
}

@media screen and (max-width: 480px) {
    .modal {
        width: 300px;

        .options {
            flex-direction: column;
            gap: 20px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
        }
    }

    .form {
        text-align: left;
    }
}
