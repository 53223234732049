.list {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    width: calc(100% - 21.88vw - 12.5vw);
    margin-left: 21.88vw;
    margin-right: 12.5vw;
    list-style: none;
    font-family: "Montserrat";

    li {
        width: 100%;
        padding: 0 15px;
        height: 40px;
        display: flex;
        box-sizing: border-box;
    }

    .header {
        align-items: center;
        justify-content: space-between;
        background: #259acf;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 138%;
        color: #ffffff;
    }

    .item {
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        text-align: right;
    }
}

.date {
    width: 72px;
}

.description {
    width: 156px;
}

.total {
    width: 102px;
}

.status {
    width: 53px;
}

.link {
    width: 100px;

    a {
        display: flex;
        gap: 5px;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #259acf;
    }
}
