.info {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 21.88vw - 12.5vw);
    margin-left: 21.88vw;
    margin-right: 12.5vw;
    font-family: "Montserrat";

    &-title {
        margin: 0;
        padding: 5px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #333333;
    }

    &-value {
        padding: 5px;
        width: 170px;
        height: 35px;
        background-color: #F8F8F8;
        border: 1px solid #DADEE8;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #6E6E6E;
        text-align: left;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-input {
        position: relative;

        input {
            padding: 5px;
            padding-right: 27px;
            width: 170px;
            height: 35px;
            background-color: #F8F8F8;
            border: 1px solid #DADEE8;
            border-radius: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #6E6E6E;
            text-align: left;
            box-sizing: border-box;
        }

    }

    .icon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
}

@media screen and (max-width: 1024px) {


    .info {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 768px) {

    .info {
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 5px;
    }

}

@media screen and (max-width:480px) {

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}