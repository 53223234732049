.item {
	font-family: "Montserrat";
	display: flex;
	gap: 10px;
	flex-direction: column;
	width: 100%;
	padding-left: 21.88vw;
	padding-right: 12.5vw;
	border-bottom: 1px solid #cecece;
	box-sizing: border-box;
	padding-bottom: 10px;
}

.item-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;

	.title {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
		font-weight: 800;
		font-size: clamp(22px, 1.72vw, 33px);
		line-height: 100%;
		color: #0466ad;
	}

	.status {
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 110%;
		padding: 3px 6px;
		border-radius: 8px;

		&.active {
			color: rgb(32, 119, 32);
			border: 2px solid rgb(32, 119, 32);
		}

		&.canceled {
			color: rgb(158, 17, 17);
			border: 2px solid rgb(158, 17, 17);
		}

		&.shared {
			color: rgb(4 102 173);
			border: 2px solid rgb(4 102 173);
		}
	}
}

.item-footer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.item-footer-btns {
	display: flex;
	gap: 10px;
}

.btn {
	padding: clamp(12px, 0.83vw, 16px) 18px;
	display: flex;
	align-items: center;
	gap: clamp(20px, 1.56vw, 25px);
	height: 100%;
	max-height: 60px;
	background-color: #0466ad;
	color: #fff;
	border: 1px solid transparent;
	border-radius: 8px;
	font-family: "Montserrat";
	font-weight: 500;
	font-size: clamp(17px, 1.15vw, 22px);
	line-height: 120%;
	cursor: pointer;
	transition: 300ms;
	margin-top: 5px;

	svg {
		width: clamp(16px, 1.04vw, 20px);

		path {
			transition: 300ms;
		}
	}

	a {
		font-family: "Montserrat";
		color: #fff;
		text-decoration: none;
		transition: 300ms;
	}

	&:hover {
		border: 1px solid #6e6e6e;
		background-color: #fff;
		color: #6e6e6e;

		svg {
			path {
				fill: #6e6e6e !important;
			}
		}

		a {
			color: #6e6e6e;
		}
	}
}

.options {
	display: flex;
	gap: 15px;
	position: relative;

	&-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		text-transform: capitalize;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #0f7cc1;
		transition: 300ms;
		cursor: pointer;
		width: clamp(35px, 2.3vw, 44px);
		height: clamp(35px, 2.3vw, 44px);
		border: 2px solid #0f7cc1;
		border-radius: 50%;
		box-sizing: border-box;

		&:hover {
			opacity: 0.6;
		}

		svg {
			width: clamp(17px, 1.15vw, 22px);
		}
	}

	&-menu {
		border: 2px solid #0f7cc1;
		border-radius: 15px;
		position: absolute;
		top: 0;
		right: 0;
		height: fit-content;
		overflow: hidden;
		z-index: 1;
		text-align: right;

		&_close {
			visibility: hidden;
			pointer-events: none;
			width: 44px;
			max-height: 44px;
			transition: width 0.4s ease-in-out, max-height 0.4s ease-in-out, visibility 0.4s cubic-bezier(1, 0, 1, 0),
				background 0.4s cubic-bezier(1, 0, 1, 0);
		}

		&_open {
			width: 220px;
			max-height: 500px;
			background: #f3f3f3;
			transition: all 0.2s ease-in-out;
		}
	}

	&-list {
		padding: 0;
		margin: 0;
	}

	&-list_item {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
		padding: 10px;
		background: none;
		border: none;
		display: flex;
		align-items: center;
		gap: 4px;
		color: #0f7cc1;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}

	&-close_btn {
		position: absolute;
		cursor: pointer;
		background: none;
		border: none;
		padding: 2px 2px 2px 2px;
		z-index: 10;
		right: 5px;
		top: 5px;
		&:hover {
			opacity: 0.8;
		}
	}
}

.info {
	display: flex;
	justify-content: flex-start;
	width: fit-content;
	gap: clamp(15px, 2.5vw, 48px);
	margin-bottom: 30px;

	&-item {
		min-width: 90px;
		max-width: clamp(130px, 8.75vw, 168px);
		color: #6e6e6e;
	}

	&-title {
		margin: 0 0 5px;
		padding: 5px 0;
		font-weight: 800;
		font-size: clamp(16px, 1.15vw, 22px);
		line-height: 100%;
		user-select: none;
	}

	&-value {
		position: relative;
		padding: 0;
		width: 100%;
		height: 35px;
		font-weight: 400;
		font-size: clamp(14px, 0.94vw, 18px);
		line-height: 120%;
		color: #6e6e6e;
		overflow: hidden;

		> span {
			font-weight: 500;
			padding: 6px 0 6px;
			display: inline-block;
			width: calc(100% - 21px);
			text-align: left;
			box-sizing: border-box;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			user-select: all;
		}
	}

	&-copy {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		position: relative;
		left: 0;
		top: -6px;
		width: 20.86px;
		height: 24.02px;
		cursor: pointer;
		border: 0;
		background: none;

		&:hover {
			opacity: 0.8;
		}

		+ span {
			padding: 6px 0 6px 7px;
		}
	}
}

@media screen and (max-width: 1024px) {
	.item {
		margin: auto;

		.info {
			flex-wrap: wrap;
		}
	}
}

@media screen and (max-width: 768px) {
	.item {
		height: auto;
	}
}

@media screen and (max-width: 480px) {
	.info {
		&-item {
			min-width: 300px;
		}
	}

	.item {
		.title {
			font-size: 18px;
		}

		.options {
			flex-direction: column;
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

.share {
	padding: 10px 0 15px;
	&_title {
		margin: 0;
		padding: 5px 0;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #333333;
		-webkit-user-select: none;
		user-select: none;
	}

	&_item {
		position: relative;
	}

	&_error {
		position: absolute;
		top: 100%;
		left: 8px;
		font-weight: 500;
		color: red;
		font-size: 13px;
	}

	&_item-wrapper,
	&_item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px;
	}

	&_input {
		padding: 5px;
		width: fit-content;
		height: 35px;
		background-color: #f8f8f8;
		border: 1px solid #dadee8;
		border-radius: 10px;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #6e6e6e;
		text-align: left;
		box-sizing: border-box;
		overflow-y: hidden;
		white-space: nowrap;
		display: inline-flex;
		gap: 8px;
	}

	&_btn {
		background: none;
		outline: none;
		border: 1px solid #dadee8;
		cursor: pointer;
		box-sizing: border-box;
		height: 35px;
		width: 25px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		color: #6e6e6e;
		margin-right: 12px;
	}
}
