.error_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 74px - 40px);

    h1 {
        font-weight: 900;
        font-size: clamp(50px, 4.2vw, 80px);
        line-height: 120%;
        margin: 0;
    }

    h2 {
        font-weight: 600;
        font-size: clamp(16px, 2vw, 38px);
    }

    h2,
    h1 {
        background: repeating-linear-gradient(45deg, #0d70b7, #38c8f3 1px, white 2px, white 2px);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
