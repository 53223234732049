.FreePlanForm {
  display: flex;
  flex-direction: column;
  width: 290px;
  margin-bottom: 2rem;
  background-color: transparent;
}
.FreePlanForm__logo {
  margin-bottom: 16px;
  width: 67px;
  height: 62px;
}
.FreePlanForm__text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #0466AD;
  margin-bottom: 25px;
}
.bold {
  font-family: 600;
}
.FreePlanForm__heading {
  font-size: 14px;
  line-height: 22px;

  color: #333333;
  font-weight: 600;
}
.FreePlanFormm__row {
  margin-bottom: 25px;
}
.FreePlanForm__heading span {
  color: red;
}
.FreePlanForm__input {
  margin-top: 6px;
  box-shadow: none;
  background: #FFFFFF;
  border: 1px solid #DADEE8;
  box-sizing: border-box;
  border-radius: 10px;
  width: 290px;
  height: 40px;
  outline: none;
}
.FreePlanForm__button {
  width: 140px;
  height: 32px;
  left: calc(50% - 140px/2 - 74px);
  top: calc(50% - 32px/2 + 239px);

  background: #0466AD;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 30px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  outline: none;
  cursor: pointer;
  border: none;
}
.FreePlanForm__button:disabled {
  background: grey;
}
.FreePlanForm__row {
  margin-bottom: 25px;
}
@media screen and (max-width: 600px) {
  .FreePlanForm__button {
    margin: 0;
  }
}
