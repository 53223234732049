.ProgressBar {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.ProgressBar-step__arrow {
  position: relative;
  top: -1px
}

.ProgressBar-step {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: calc(14px * 0.8);
  line-height: calc(17px * 0.8);
  color: #6e6e6e;
  margin: 0 15px;
  transition: all .3s ease-in-out;
}

.ProgressBar-step__number {
  min-width: calc(27px * 0.8);
  max-width: calc(27px * 0.8);
  min-height: calc(27px * 0.8);
  max-height: calc(27px * 0.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #666666;
  box-sizing: border-box;
  margin-right: calc(15px * 0.8);
  font-weight: 800;
  transition: all .1s ease-in-out;
  overflow: hidden;
  position: relative;
}

.ProgressBar-step__number::before {
  content: '';
  background: linear-gradient(89.77deg, #0D70B7 -3.21%, #38C8F3 99.71%);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  transition: all .3s ease-in-out;
}

.ProgressBar-step.active {
  color: #259ACF;
}

.ProgressBar-step.active .ProgressBar-step__number {
  border: 0;
  padding: 1px;
  color: #fff;
}

.ProgressBar-step.active .ProgressBar-step__number b {
  z-index: 2;
}

.ProgressBar-step.active .ProgressBar-step__number::before {
  opacity: 1;
  z-index: 0;
}

.CheckoutForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.CheckoutForm-agree-and-subscribe-button {
  padding: 7px 30px;
  width: 220px;
  height: 32px;
  background: #0466ad;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin-right: 22px;
}

.CheckoutForm-continue-button {
  padding: 7px 30px;
  width: fit-content;
  background: #0466ad;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 20px auto 20px;
}

.CheckoutForm-continue-button:disabled {
  background-color: grey;
}

.CheckoutForm-back-button {
  padding: 7px 20px;
  background: #0466ad;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-right: 10px;
}

.CheckoutForm-secure-sign {
  background-image: url("/static/icon-lock-protected.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 23px;
  z-index: 10000;
  cursor: pointer;
  margin-left: 22px;
}

.CheckoutForm-confirmation-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.CheckoutForm-payment-form__input {
  margin-top: 6px;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #dadee8;
  box-sizing: border-box;
  border-radius: 10px;
  width: 351px;
  height: 40px;
  outline: none;
}

.CheckoutForm-payment {
  display: flex;
  flex-direction: column;
  width: 352px;
  height: 500px;
  background-color: transparent;
}

.CheckoutForm-payment-form {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
}

.CheckoutForm-information-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 6px;
}

.CheckoutForm-information-content {
  width: 400px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 26px 23px 29px 23px;
}

.CheckoutForm-information-plan {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 20px;
  margin-bottom: 40px;
  color: #0466ad;
}

.CheckoutForm-information-plan svg {
  margin-right: 10px;
}

.CheckoutForm-information-plan__text {
  font-size: 18px;
  line-height: 22px;
  color: #0466ad;
  width: 137px;
  height: 44px;
}

.CheckoutForm-information-plan__price-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 6px;

  position: absolute;
  height: 27px;
  right: 0;
  top: 50%;
  border-radius: 3px;
  font-size: 12px;
  line-height: 15px;

  text-align: center;

  color: #259acf;
  border: 2px solid #259acf;
}

.CheckoutForm-information-plan__text--bold {
  font-weight: 600;
}

.CheckoutForm-payment-agreement-info {
  font-size: 12px;
  line-height: 16px;

  color: #6e6e6e;
  margin-bottom: 24px;
}

.CheckoutForm-payment-agreement-info span {
  font-size: 12px;
  line-height: 16px;

  color: #6e6e6e;
  margin-bottom: 24px;
}

.CheckoutForm-payment-form__heading {
  font-size: 14px;
  line-height: 22px;

  color: #333333;
  font-weight: 600;
}

.CheckoutForm-payment-form__row {
  margin-bottom: 25px;
}

.CheckoutForm-payment-form__heading span {
  color: red;
}

.CheckoutForm-coupon__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 6px;
}

.CheckoutForm-coupon__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 20px;
}

.CheckoutForm-coupon__content--input {}

.CheckoutForm-button {
  background: #dadee8;
  border-radius: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  outline: none;
  border: none;
  color: #0466ad;
  width: 115px;
  height: 40px;
}

.CheckoutForm__content--coupon {
  background: #f8f8f8;
  border: 1px solid #dadee8;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  width: 183px;
  box-shadow: none;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.CheckoutForm__content--coupon::placeholder {
  font-size: 14px;
  line-height: 22px;

  color: #6e6e6e;
  opacity: 0.5;
}

.Checkout-form-information__pair-of-label-and-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.Checkout-form-information__pair-of-label-and-select--label {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 6px;
}

.Checkout-form-information__pair-of-label-and-select--label span {
  color: red;
}

.Checkout-form-information__pair-of-label-and-select--select {
  width: 100%;
}

.Checkout-form-information__warning {
  margin-bottom: 28px;
  display: flex;
  flex-direction: row;
}

.Checkout-form-information__warning span {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.bold {
  font-weight: 600;
}

.Checkout-form-information__line {
  width: calc(100% + 46px);
  margin-left: -23px;
  margin-bottom: 20px;
}

.Checkout-form-information__warning--img {
  margin-right: 14px;
}

.Checkout-form-information__money-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.Checkout-form-information__money-info--row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Checkout-form-information__money-info--heading {
  font-weight: normal;
}

.Checkout-form-information__money-info--amount {
  font-weight: bold;
}

.blue {
  color: #38c8f3;
}

.CheckoutForm__hidden-information {
  width: 347px;
  height: 63px;
  background-color: #6e6e6e;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 12px;
  opacity: 0;
  transition: 0.5s;
  z-index: 10000;
}

.CheckoutForm-secure-sign:hover .CheckoutForm__hidden-information {
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .CheckoutForm {
    flex-direction: column;
    align-items: center;
    width: 352px;
  }

  .CheckoutForm-payment {
    margin-bottom: 40px;
  }

  .CheckoutForm-information {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 660px) {
  .CheckoutForm-confirmation-container {
    position: relative;
  }

  .CheckoutForm__hidden-information {
    width: calc(100vw - 40px);
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

@media screen and (max-width: 430px) {
  .CheckoutForm {
    width: 314px;
  }

  .CheckoutForm-payment {
    width: 314px;
  }

  .CheckoutForm-information {
    width: 314px;
  }

  .CheckoutForm-payment-form__input {
    width: 314px;
  }

  .CheckoutForm-information-content {
    width: 314px;
  }

  .CheckoutForm-information-plan {
    height: 60px;
    flex-direction: column;
  }

  .CheckoutForm-information-plan svg {
    margin-bottom: 10px;
  }
}