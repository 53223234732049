.list {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: clamp(30px, 3.65vw, 70px);
}

.empty {
    margin-top: clamp(79px, 6vw, 116px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29px;
    color: #6e6e6e;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
}
