.form {
    margin: auto;
    font-family: "Montserrat";
    color: #333333;
    width: 100%;
    padding-left: 21.88vw;
    padding-right: 12.5vw;
}

.form-box {
    max-height: 90px;

    .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .input {
        margin-top: 8px;
        padding: 0 7px;
        width: 100%;
        height: 40px;
        outline-color: transparent;
        border: 1px solid #dadee8;
        border-radius: 10px;
        box-sizing: border-box;
        transition: 300ms;

        &:disabled {
            background: #fff;
        }

        &:focus {
            outline-color: #259acf;
        }
    }

    .error {
        margin: 0;
        height: 22px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #ca0000;
    }
}

.options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
}

.btn {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 20px;
    background-color: #0466ad;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 8px;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    transition: 300ms;

    &:hover:not(:disabled) {
        border: 1px solid #6e6e6e;
        background-color: #fff;
        color: #6e6e6e;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.delete-btn {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ca0000;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    color: #ca0000;
    cursor: pointer;
    opacity: 0.5;
    transition: 300ms;

    &:hover {
        opacity: 1;
    }
}

@media screen and (max-width: 1024px) {
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        &-box {
            width: 100%;
            display: flex;
            flex-direction: column;

            .input {
                width: 100%;
            }
        }

        .options {
            width: 100%;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 480px) {
    .options {
        flex-direction: column-reverse;
        gap: 30px;

        .btn,
        .delete-btn {
            justify-content: center;
        }
    }
}
